<template>
  <ProgressBar
    v-bind="$attrs"
    :value="toFixedValue"
  >
    <slot />
  </ProgressBar>
</template>

<script>
import ProgressBar from 'primevue/progressbar';

/*
  PV's progressbar does not limit decimals so this is a handy
  override component that does. Exactly the same except it also
  accepts a toFixed prop where you can specify how many decimals you want
*/
export default {
  name: 'SwProgressBar',
  components: { ProgressBar },
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String, null],
      required: true,
    },
    toFixed: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    toFixedValue() {
      const value = Number(this.value);
      if (Number.isNaN(value)) return 0;
      return Number(value.toFixed(this.toFixed));
    },
  },
};
</script>
